body{
  background: linear-gradient(87deg,#2dce89,#2dcecc) !important;
}
*,input,button{
  font-family: 'Roboto', sans-serif;
}

.table{
  margin-top: 10%;
  background-color: #fff;
  border-radius: 9px;
}

.destinyKm{
  background-color: #9af1ff !important
}
.onBoardingKm{
   background-color: #FAFBAF !important;
}
.signature{
 background-color: #ff7f7f !important;
}
.boarding{
   background-color: #6E4BE3 !important;
   color: #FFFF !important ;
}
